import { Button } from '@shared/components';
import { Notifications } from '@shared/features/notifications/Notifications';
import { Menu } from '@shared/plugin/features/Menu';
import { QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { motion } from 'framer-motion';
import { GoArrowLeft } from 'react-icons/go';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

const INCLUDE_NOTIFICATIONS = false;

interface LayoutProps {
  children: React.ReactNode;
  hasMenu?: boolean;
  navigateBackTo?: 'quote-page';
  webBaseUrl: string;
}

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      duration: 0.6,
    },
  },
};

export const Layout = ({
  hasMenu,
  children,
  navigateBackTo,
  webBaseUrl,
}: LayoutProps) => {
  const navigate = useNavigate();
  const { quoteId } = useParams();

  function navigateBackHandler() {
    if (!navigateBackTo) {
      navigate(-1);
      return;
    }
    switch (navigateBackTo) {
      case 'quote-page':
        if (quoteId) {
          navigate(`${QUOTES_ROUTE}/${quoteId}`);
        }
    }
  }
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="text-body-md flex h-screen flex-col overflow-hidden"
    >
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="z-10 flex flex-row items-center justify-between bg-white p-2 text-gray-700">
          {hasMenu ? (
            <div className="flex flex-row items-center gap-2">
              <Menu />
              {INCLUDE_NOTIFICATIONS && (
                <Notifications
                  knockApiKey={import.meta.env.VITE_KNOCK_PUBLIC_API_KEY}
                />
              )}
            </div>
          ) : (
            <Button
              onPress={navigateBackHandler}
              variant="secondary"
              size="xs"
              icon={<GoArrowLeft size={12} />}
            >
              Back
            </Button>
          )}

          <a href={webBaseUrl} target="_blank" rel="noreferrer">
            <Button
              size="xs"
              icon={<MdOutlineOpenInNew className="text-brand-500" />}
              tooltip="Open Vooma web app"
              variant="secondary"
              iconPosition="right"
            >
              Vooma
            </Button>
          </a>
        </div>
        <div className="flex flex-1 flex-col overflow-y-hidden bg-gray-50">
          {children}
        </div>
      </div>
    </motion.div>
  );
};
