import { isHostedInWeb } from '@/utils/hostingApp';
import Login from '@shared/features/login/Login';

const OutlookLogin = () => {
  return (
    <Login
      authBaseUrl={import.meta.env.VITE_API_BASE_URL}
      displayLogo={isHostedInWeb(Office.context.mailbox.diagnostics.hostName)}
      appName="Outlook"
    />
  );
};

export default OutlookLogin;
